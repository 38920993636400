<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row justify="center" v-if="$route.params.promotion != 'crear'">
      <v-col cols="8" class="pa-0 text-right">
        <v-spacer />
        <template>
          <span class="px-2 font-weight-bold">Traducir:</span>
          <v-btn icon color="sidebar" @click="promotionLang('en')">
            <div style="font-size:18px"><flag iso="us" /></div>
          </v-btn>
          <v-btn icon color="sidebar" @click="promotionLang('fr')">
            <div style="font-size:18px"><flag iso="fr" /></div>
          </v-btn>
          <v-btn icon color="sidebar" @click="promotionLang('zh')">
            <div style="font-size:18px"><flag iso="cn" /></div>
          </v-btn>
        </template>
      </v-col>
    </v-row>
    <validation-observer ref="promotion_form" v-slot="">
      <v-row justify="center">
        <v-col cols="12" sm="8" md="8" lg="8" class="pb-0">
          <validation-provider name="Título" vid="name" rules="required" v-slot="{ errors }">
            <v-text-field dense rounded single-line label="Título de la Promoción" outlined :error-messages="errors[0]"  v-model="promotion.name"></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="8" md="8" lg="8" class="pt-0 editor">
          <v-card flat class="mt-2">
            <v-card-text class="d-flex align-center justify-center pa-0" style="height:20rem"  v-if="cargaInicialImagen" :class="{'imagezone': !cargaInicialImagen}">
              <template>
                <div class="v-responsive v-image grey lighten-2" :class="{'d-none': !cargaInicialImagen}" style="height:20rem; width:100%">
                  <div id="imagePreviewpromotion" class="v-image__image v-image__image--cover">
                  </div>
                   <v-overlay absolute :opacity="0.1" :value="true" color="light-blue lighten-5">
                    <v-btn icon x-large color="orange lighten-2" @click="deleteImage">
                      <v-icon size="40">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-overlay>
                </div>
              </template>
            </v-card-text>
            <v-card-text class="d-flex align-center justify-center pa-0" style="height:20rem"  v-if="!cargaInicialImagen" :class="{'imagezone': cargaInicialImagen}">
              <template>
                <div class="v-responsive v-image grey lighten-2" :class="{'d-none': cargaInicialImagen}" style="height:20rem; width:100%">
                  <croppa
                    :width="700"
                    :height="300"
                    :quality="1"
                    style="margin:auto"
                    placeholder="Elige la imagen del menu"
                    :placeholder-font-size="18"
                    v-model="imagenPromocion"
                    initial-position="center"
                    :show-remove-button="true"
                    remove-button-color="black"
                  >
              </croppa>
                </div>
              </template>
            </v-card-text>
            <!-- <validation-provider name="Imagen" vid="image" rules="required" v-slot="{ errors }">
               <v-text-field class="d-none" v-model="promotion.image"></v-text-field>
              <div class="v-messages theme--light error--text"> <span class="v-messages__message" v-text="errors[0]"></span></div>
            </validation-provider> -->
            <v-card-actions class="pr-0">
              <v-spacer />
              <v-btn class="py-2 font-weight-bold mr-0" rounded outlined color="primary" dense @click="validarPromotion(0)" :small="$vuetify.breakpoint.smAndDown">Guardar borrador</v-btn>
              <v-btn class="py-2 font-weight-bold" rounded color="primary" dense  @click="validarPromotion(1)" :small="$vuetify.breakpoint.smAndDown" v-text="promotion.visibility == 0 ? 'Publicar' : $route.params.promotion != 'crear' ? 'Actualizar' : 'Publicar'"></v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </validation-observer>
    <!-- *******MODAL DE TRADUCCIONES****** -->
    <base-material-modal v-model="modal_promo" icon="mdi-flag" :loadcard="saving_lang" :title="`Añadir Traducción (${lang})`" width="500">
      <template v-slot:content>
        <validation-observer ref="promo_lang" v-slot="">
          <v-row>
            <v-col cols="12" class="pb-0">
              <validation-provider name="Título" vid="name" rules="required" v-slot="{ errors }">
                <v-text-field v-model="traslate.name" dense rounded single-line prepend-inner-icon="mdi-playlist-edit" label="Título del Plato" outlined :error-messages="errors[0]"></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </template>
      <template v-slot:actions>
         <v-btn
            depressed
            small
            @click="modal_promo = false"
            :disabled="saving_lang"
          >
            Descartar
          </v-btn>
          <v-btn color="primary" tile small :loading="saving_lang" @click="validarLang">
            Guardar traducción
          </v-btn>
      </template>
    </base-material-modal>
    <v-overlay absolute :value="load_promotion.active" :opacity="0.95" :z-index="10" color="white" class="text-center">
      <v-img
        contain
        max-width="120"
        :src="require('@/assets/Todo-carta-logo.png')"
      ></v-img>
      <v-progress-linear
          color="blue-grey lighten-3"
          indeterminate
          rounded
          height="4"
        ></v-progress-linear>
      <h5 class="blue-grey--text mt-4" v-text="load_promotion.text"></h5>
    </v-overlay>
  </v-container>
</template>

<script>
  export default {
    name: 'DashboardDashboard',
    data () {
      return {
        imagenPromocion :{},
        cargaInicialImagen:true,
        promotion:{
          image:'',
          name: '',
          image_delete: false,
          visibility: '',
          lang: 'es',
          restaurant_id:'',
          id: '',
        },
        traslate:{
          name: '',
          lang: '',
        },
        promotion_lang:{
          name: ''
        },
        saving_lang: false,
        load_promotion:{
          active: false,
          text:''
        },
        modal_promo: false,
        redirect: '',
      }
    },
    created(){
       if(this.$store.getters['auth/isAdmin']){
          this.promotion.restaurant_id = this.$route.params ? this.$route.params.id : '';
          this.redirect = `/tc-admin/restaurante/${this.promotion.restaurant_id}/administrar/promociones`;
        }
        else{
          this.promotion.restaurant_id =  this.$store.getters['auth/restaurant'];
          this.redirect = '/tc-admin/promociones';
        }

        if(this.$route.params.promotion && this.$route.params.promotion != "crear"){
          this.promotion.id =  this.$route.params.promotion;
          this.getPromotion();
        }
    },
    beforeRouteUpdate (to, from, next) {
      let Exp = /^([0-9])*$/;
        if(Exp.test(to.params.promotion) || to.params.promotion === 'crear'){
          this.resetPromo();
          if(this.$store.getters['auth/isAdmin']){
            this.promotion.restaurant_id = to.params ? to.params.id : '';
            this.redirect = `/tc-admin/restaurante/${this.promotion.restaurant_id}/administrar/promociones`;
          }
          else{
            this.promotion.restaurant_id =  this.$store.getters['auth/restaurant'];
            this.redirect = '/tc-admin/promociones';
          }

          if(to.params.promotion && to.params.promotion != "crear"){
            this.promotion.id =  to.promotion;
            this.getPromotion();
          }
          next();
        }
        else{
          next('/tc-admin');
        }
    },
    methods: {
      resetPromo(){
        this.promotion = {
          image:'',
          name: '',
          image_delete: false,
          visibility: '',
          lang: 'es',
          restaurant_id:'',
          id: '',
        };
        this.traslate = {
          name: '',
          lang: '',
        };
        this.promotion_lang = {
          name: ''
        };
        const preview = document.querySelector('#imagePreviewpromotion');
        preview.style.backgroundImage = 'none';
      },
      async getPromotion(){
        this.load_promotion= {active: true, text:'Obteniendo datos'};
        try{
          const { data } = await this.$api.get(`api/promotions/${this.promotion.id}/edit`);

          this.promotion.name = data.data.promotion.name.es;
          this.promotion.visibility = data.data.promotion.visibility;
          this.promotion.image = data.data.promotion.imageUrl;
          this.promotion_lang.name = data.data.promotion.name;

          /* *****ASIGNAR IMAGEN EN PANTALLA***** */
          const preview = document.querySelector('#imagePreviewpromotion');
          preview.style.backgroundImage = `url("${process.env.VUE_APP_IMG_URL_API}/images/promotions/${this.promotion.image }")`;
          preview.style.backgroundSize = 'contain';
          preview.style.backgroundPosition = 'center center';

          this.load_promotion.active= false;

        }
        catch(error){
          this.load_promotion.active= false;
          this.$root.$snakbar({
            text: 'Lo sentimos, hubo un error al intentar obtener las Promociones',
            type: 'error',
            active:true,
          });

        }
      },
      validarPromotion(visibility){
        this.$refs.promotion_form.validate().then(result =>{
          if(result){
            this.promotion.visibility = visibility;
            this.savePromotion();
          }
        })
      },
      validarLang(){
        this.$refs.promo_lang.validate().then(result =>{
          if(result){
            this.savePromotion(true);
          }
        })
      },
      async savePromotion(lang = false){
        let promocion;
        const datapromotion = new FormData;
        datapromotion.append('name', lang ? this.traslate.name : this.promotion.name);
        datapromotion.append('visibility', this.promotion.visibility);
        datapromotion.append('lang', lang ? this.traslate.lang : this.promotion.lang);
        datapromotion.append('restaurant_id', this.promotion.restaurant_id);
        datapromotion.append('image_delete', +this.promotion.image_delete);

        datapromotion.append('id', this.promotion.id);

        if(lang) this.modal_promo = false;

        this.load_promotion= {active: true, text:'Guardando promoción'};

        if(Object.keys(this.imagenPromocion).length != 0 ){
          //Compruebo si tiene una imagen valida
          if (this.imagenPromocion.hasImage()) {
            promocion = this.imagenPromocion.promisedBlob((blob) =>{
            },'image/jpeg',0.7);
          }
        }

        if (promocion) {
          promocion.then(res => {
            datapromotion.append('image', res, 'filename.jpeg')
          }).then(res => {
            const send_promotion = this.$api({
              url: 'api/promotions',
              method: 'post',
              data: datapromotion,
            });

            send_promotion.then(res =>{
              this.$root.$snakbar({
                text: res.data.message,
                type: 'success',
                active: true
              });
              if(lang){
                this.getPromotion();
              }
              else{
                this.$router.push(`${this.redirect}`);
              }
              this.load_promotion.active= false;

            })
          })
        }else{
          try{
            const send_promotion = await this.$api({
              url: 'api/promotions',
              method: 'post',
              data: datapromotion,
            });
            this.$root.$snakbar({
              text: send_promotion.data.message,
              type: 'success',
              active: true
            });
            if(lang){
              this.getPromotion();
            }
            else{
              this.$router.push(`${this.redirect}`);
            }
            this.load_promotion.active= false;

          }
          catch(e){
            if(e.response.status == "422"){
              this.$refs.promotion_form.setErrors(e.response.data.data);
            }
            else{
              this.$root.$snakbar({
                text: e.response.data.message,
                type: 'error',
                active: true
              });

            }
            this.load_promotion.active= false;
          }
        }
      },
      renderImage(file){
        const image = file.target.files[0] || file.dataTransfer.files
        if(image.length === 0) return;

        this.promotion.image = image;
        this.promotion.image_delete = false;

        const preview = document.querySelector('#imagePreviewpromotion');

        let reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onload = () => {
          if (image.type.match('image/png') || image.type.match('image/jpeg') || image.type.match('image/gif')) {
            preview.style.backgroundImage = `url("${ reader.result }")`;
            preview.style.backgroundSize = 'contain';
            preview.style.backgroundPosition = 'center center';
          }
        };
      },
      deleteImage(){
        this.promotion.image = '';
        this.promotion.image_delete = true;
        this.cargaInicialImagen = false;

      },
      promotionLang(lang){
        this.traslate.name = this.promotion_lang.name[lang] ? this.promotion_lang.name[lang] : this.promotion_lang.name.es;
        this.traslate.lang = lang;
        this.modal_promo = true;
      }
    },
    computed:{
      date(){
        const f = new Date();
        return f.toDateString();
      },
      lang(){
        let traslate = '';
        switch(this.traslate.lang){
          case 'en':
            traslate = 'Inglés';
          break;
          case 'fr':
            traslate = 'Francés';
          break;
          case 'zh':
            traslate = 'Chino';
          break;
          default:
            traslate = '';
        }

        return traslate;
      }
    }
  }
</script>
<style scope>
  .v-data-table__expand-icon{
    color: #00cae3 !important;
    font-size: 30px !important;
  }

  .sidebar_right{
    background: #fbfcfd !important;
  }

</style>
